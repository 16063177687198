import { Heading, majorScale, Pane, Spinner, toaster } from 'evergreen-ui'
import { FC, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'
import { ContentEditorDetails } from '../../../components/ContentEditor/ContentEditor'
import PostDetailCard from '../../../components/PostDetailCard/PostDetailCard'
import { CONSTANTS } from '../../../constants'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import {
  useEditPostMutation,
  useGetPostByIdQuery,
  useSubscribeUserToPostMutation,
} from '../../../services/posts/posts.service'
import { EditPostRequestPayload, Post, User } from '../../../types'
import {
  generatePageTitle,
  generateSubpageTitle,
} from '../../../utils/generate-page-title'
import { setActivePost } from '../Community.slice'
import styles from './PostDetailPage.module.scss'

interface PostDetailProps {}

const PostDetailPage: FC<PostDetailProps> = (props: PostDetailProps) => {
  const [title, setTitle] = useState<string>(
    generatePageTitle(CONSTANTS.TITLES.COMMUNITY),
  )

  const dispatch = useAppDispatch()
  let params = useParams()

  // the active post ID
  const [postId, setPostId] = useState<string>('')
  const [skipCallingApiOnLoad, setSkipCallingPostApi] = useState(true)

  // get props from state
  const activePost = useAppSelector((state) => state.community.activePost)
  const user: User | undefined = useAppSelector((state) => state.auth.user)

  // Get individual post data
  const { data: postData, isFetching: isGetPostLoading } = useGetPostByIdQuery(
    postId,
    {
      skip: skipCallingApiOnLoad,
      refetchOnMountOrArgChange: true,
    },
  )

  // save the data on page load
  useEffect(() => {
    if (postData) {
      dispatch(setActivePost(postData))
      setTitle(
        generatePageTitle(
          generateSubpageTitle(CONSTANTS.TITLES.COMMUNITY, postData.title),
        ),
      )
    }
  }, [postData])

  // enable the API once we have the post ID
  useEffect(() => {
    if (!postId || postId === '') {
      dispatch(setActivePost(undefined))
      setTitle(generatePageTitle(CONSTANTS.TITLES.COMMUNITY))
      setSkipCallingPostApi(true)
    } else if (postId && postId !== '') {
      setSkipCallingPostApi(false)
    } else {
      dispatch(setActivePost(undefined))
    }
  }, [postId])

  // edit a comment
  const [
    callEditPostAPI,
    { isLoading: isEditingPost, isSuccess: isEditSaveSuccess },
  ] = useEditPostMutation()

  // subscribe a user to a post
  const [subscribeUserToPost] = useSubscribeUserToPostMutation()

  // listen to the route to make sure we get
  // the route param required to make API call
  useEffect(() => {
    if (params.postId) {
      setPostId(params.postId)
    } else {
      setPostId('')
    }
  }, [params.postId])

  const subscribeUserToCurrentPost = async (subscribed: boolean) => {
    try {
      await subscribeUserToPost({ post_id: postId, subscribed }).unwrap()
      toaster.success(`You have been ${subscribed ? 'subscribed' : 'unsubscribed'} to notifications on this post!`)
    } catch (error) {
      toaster.danger('Error updating subscription status.')
    }
  }

  // edit a comment
  const editPost = async (newContent: ContentEditorDetails) => {
    try {
      const payload: EditPostRequestPayload = {
        id: postId,
        content: newContent.content,
      }
      const savedPost: Post = await callEditPostAPI(payload).unwrap()

      dispatch(setActivePost(savedPost))

      toaster.success('Post updated!')
    } catch (exception) {
      toaster.danger('Error updating post.')
    }
  }

  const renderPost = () => {
    // show spinner if loading
    if (isGetPostLoading) {
      return (
        <Pane
          display="flex"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          width="100%"
        >
          <Spinner />
        </Pane>
      )
    }

    // show 'select something' text if no active post
    if (!activePost) {
      return (
        <Pane
          display="flex"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          width="100%"
        >
          <Heading size={800} display="inline" className={styles.NoContent}>
            Select a post.
          </Heading>
        </Pane>
      )
    }

    // show the post
    return (
      <PostDetailCard
        post={activePost}
        user={user}
        isEditSaveSuccess={isEditSaveSuccess}
        editPost={(content) => {
          editPost(content)
        }}
        isSavingEdit={isEditingPost}
        setUserSubscribed={subscribeUserToCurrentPost}
      />
    )
  }

  return (
    <Pane
      display="flex"
      flex={4}
      className={styles.CommunityPage}
      data-testid="PostDetailPage"
      padding={majorScale(4)}
      background="tint2"
      flexDirection="column"
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {renderPost()}
    </Pane>
  )
}

export default PostDetailPage
